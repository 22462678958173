import {SocialProviders} from "../../../types";
import {FaWhatsapp, RiFacebookCircleLine, RiTwitterLine} from "react-icons/all";
import React from "react";
import {css} from "emotion";
import {app} from "../../../stores/appStore";
import {copyImageToClipboard, downloadImage, saveImage} from "../../../utils/image";



const styles = css`
  text-align: center;


  svg {
    fill: #fff;
    width: 30px;
    height: 30px;
    margin: 0 10px;
  }
`

    const handlePostDownload = () => {
        setTimeout(function () {
            app.isDownloadMode = false;
            app.hasDownloaded = true;
            localStorage.setItem('hasDownloaded', 'true');
        }, 500);
    };

 const handleImageSave = () => {
 
 window.location.replace("http://www.learnitive.com/panel");
 
        //app.isDownloadMode = true;
        //copyImageToClipboard(document.getElementById('canvas')).then(handlePostDownload)
        //console.log('Saving..');
 //console.log(app.getCanvasDimensions().height);
 //console.log(app.getCanvasDimensions().width);
// saveImage(
 //           document.getElementById('canvas'),
  //          app.getCanvasDimensions().height,
   //         app.getCanvasDimensions().width,
    //    ).then(handlePostDownload);
}
 
 

export const ShareButtons = () => {
    const handleShareClick = (provider: SocialProviders) => {
        switch (provider) {
            case SocialProviders.WhatsApp:
                window.location.href = 'whatsapp://send?text=Create%20beautiful%20browser%20mockups%20in%20seconds+https%3A%2F%2Fscreenshot.rocks';
                break
            case SocialProviders.Facebook:
                window.open('https://www.facebook.com/sharer/sharer.php?u=https%3A%2F%2Fscreenshot.rocks%20');
                break;
            case SocialProviders.Twitter:
                window.open('https://twitter.com/intent/tweet?url=https%3A%2F%2Fscreenshot.rocks%20&text=Create%20beautiful%20browser%20mockups%20in%20seconds');
                break;
        }
    }

    return (
        <div className={styles}>
            <button   disabled={!app.imageData || app.isDownloadMode}  className="btn btn-success btn-sm d-inline-flex align-items-center mr-2"
                                onClick={handleImageSave}>
                  
                            &nbsp;Learnitive.com&nbsp;
            </button>&nbsp;
        </div>
    )
};