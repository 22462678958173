import {css} from "@emotion/core";

export const styles = () => {
    return css`
      :root {
        --sidebar-width: 325px;
      }
      
      body {
        background: #040A2C;
        
      }
`;
}